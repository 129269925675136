<template>
  <div class="container-fluid" style="padding: 0px;">
    <slot name="additionalinputs"/>
    <v-row class="database-picker__search-bar pl-3 pr-3">
      <v-col md="4" sm="6" lg="3">
        <v-checkbox
            v-model="exactSearch"
            color="#93BD20"
            label="Exact match"
        />
      </v-col>
      <v-col md="8" sm="6" lg="9">
        <v-text-field
            v-model="searchQuery"
            type="text"
            append-icon="search"
            clearable
            label="Filter query"
        />
      </v-col>
    </v-row>
    <line-item class="align-items-center" :class="{'line-item--active': false}">
      <div class="col-12" v-if="struct" style="padding-top:10px;">
        <div class="row no-gutters align-self-center">
          <div
            class="data-col"
            v-for="(prop, key) in struct"
            :key="key"
            :class="{'col-2': key != 'title', 'col-5': key == 'title'}"
            style="white-space: nowrap;"
          >
            <template v-if="typeof key === 'string' && key != 'title'">{{ key.toUpperCase() }}</template>
          </div>
        </div>
      </div>
    </line-item>
    <RecycleScroller class="scroller" :items="filteredItems" :itemSize="41" key-field="pos_productID" page-mode>
      <template slot-scope="{item}">
        <line-item
          :class="{'line-item--active': activeItems.findIndex(el => el == item.id) != -1}"
          :dark="true"
          :id="item.id"
          class="align-items-center"
        >
          <div @click="handleItemClick(item)">
            <div class="col-12" v-if="struct" style="padding-top:10px;">
              <div class="row no-gutters align-self-center">
                <div
                  class="data-col"
                  v-for="(prop, key) in struct"
                  :key="key"
                  :class="{'col-2': key != 'title', 'col-5': key == 'title',}"
                  style="white-space: nowrap;"
                >
                  <template v-if="typeof prop === 'string'">{{ item[key] }}</template>
                  <template v-else>{{ prop.value(item) }}</template>
                </div>
              </div>
            </div>
          </div>
        </line-item>
      </template>
    </RecycleScroller>
  </div>
</template>

<script>
import searchBar from "../components/search_bar.vue";
import iexplorer from "../components/explorer/iexplorer.vue";
import Fuse from "fuse.js";
import toggle from "../components/cleverly/Toggle.vue";
import KeyValLine from "components/key_val_line";
import LineItem from "components/line_item";

export default {
    name: "DatabasePicker",
    components: {
        searchBar,
        iexplorer,
        toggle,
        KeyValLine,
        LineItem
    },
    props: {
        items: {
            type: Array,
            required: true
        },
        struct: {
            type: Object,
            required: true
        },
        search: {
            type: Boolean,
            default: true
        },
        searchProps: {
            type: Array,
            default: () => ["inventoryItemName"]
        },
        activeItems: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            searchQuery: "",
            exactSearch: false
        };
    },
    computed: {
        fuse() {
            const options = {
                shouldSort: true,
                threshold: 0.6,
                location: 0,
                distance: 100,
                maxPatternLength: 32,
                minMatchCharLength: 1,
                keys: this.searchProps
            };
            return new Fuse(this.items, options); // "list" is the item array
        },

        filteredItems() {
            if (this.searchQuery === null || typeof this.searchQuery === 'undefined' || this.searchQuery === "") {
                return this.items;
            }

            if (this.exactSearch) {
                return this.items.filter(el => {
                    var match = false;
                    for (let prop of this.searchProps) {
                        match =
                  match |
                  (el[prop].toLowerCase().indexOf(this.searchQuery.toLowerCase()) != -1);
                    }
                    return match;
                });
            } else {
                return this.fuse.search(this.searchQuery);
            }
        // const query = this.searchQuery.toLowerCase()
        }
    },
    methods: {
        assignHandlers(mod) {
            mod.onClick = this.handleItemClick;

            return mod;
        },
        handleItemClick(item) {
            this.$emit("selected", item);
        },
        onSearch(query) {
            this.searchQuery = query;

            if (this.$refs.list) {
                this.$refs.list.$el.scrollTop = 0;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~scss/mixins";

.container {
  height: 100%;
  padding-bottom: 0px;
}

.product-search {
  &__container {
    height: height(12);
  }
}

.list {
  max-height: calc(100% - 72px);
  overflow: auto;
}

.toggle {
  margin-left: 10px;
}

.exact-container {
  margin-left: 10px;
}

.checkbox-container {
  margin-left: 10px;
}

.folder__item {
  font-size: fz(sm);
  cursor: pointer;

  &--editing,
  &--no-action {
    cursor: default;
  }

  &-status {
    width: 10px;
    margin-left: 10px;
    border-radius: 50%;

    &.disabled,
    &.active {
      height: 10px;
    }

    &.disabled {
      background-color: $error_red;
    }

    &.active {
      background-color: $brand_color;
    }
  }

  &-remove {
    cursor: pointer;
    margin-right: 10px;
  }

  &-handle {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    &--active {
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }
  }
}

.line-item {
  height: 41px;
  cursor: pointer;
  &--pending-delete {
    color: $grey_mid_1;
    .folder__item__title {
      position: relative;
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-24px, -50%);
        background-image: url("~img/disabled.svg");
        background-repeat: no-repeat;
        background-size: 12px 12px;
        background-position: center;
        width: 12px;
        height: 12px;
      }
    }
  }
}

.data-col {
  @include below(sm) {
    font-size: 10px;
  }
}

.data-col:first-child {
  padding-left: 18px;
}


.search-container {
  padding-left: 18px;
}
</style>
