var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container-fluid", staticStyle: { padding: "0px" } },
    [
      _vm._t("additionalinputs"),
      _c(
        "v-row",
        { staticClass: "database-picker__search-bar pl-3 pr-3" },
        [
          _c(
            "v-col",
            { attrs: { md: "4", sm: "6", lg: "3" } },
            [
              _c("v-checkbox", {
                attrs: { color: "#93BD20", label: "Exact match" },
                model: {
                  value: _vm.exactSearch,
                  callback: function ($$v) {
                    _vm.exactSearch = $$v
                  },
                  expression: "exactSearch",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { md: "8", sm: "6", lg: "9" } },
            [
              _c("v-text-field", {
                attrs: {
                  type: "text",
                  "append-icon": "search",
                  clearable: "",
                  label: "Filter query",
                },
                model: {
                  value: _vm.searchQuery,
                  callback: function ($$v) {
                    _vm.searchQuery = $$v
                  },
                  expression: "searchQuery",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "line-item",
        {
          staticClass: "align-items-center",
          class: { "line-item--active": false },
        },
        [
          _vm.struct
            ? _c(
                "div",
                {
                  staticClass: "col-12",
                  staticStyle: { "padding-top": "10px" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "row no-gutters align-self-center" },
                    _vm._l(_vm.struct, function (prop, key) {
                      return _c(
                        "div",
                        {
                          key: key,
                          staticClass: "data-col",
                          class: {
                            "col-2": key != "title",
                            "col-5": key == "title",
                          },
                          staticStyle: { "white-space": "nowrap" },
                        },
                        [
                          typeof key === "string" && key != "title"
                            ? [_vm._v(_vm._s(key.toUpperCase()))]
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _c("RecycleScroller", {
        staticClass: "scroller",
        attrs: {
          items: _vm.filteredItems,
          itemSize: 41,
          "key-field": "pos_productID",
          "page-mode": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ item }) {
              return [
                _c(
                  "line-item",
                  {
                    staticClass: "align-items-center",
                    class: {
                      "line-item--active":
                        _vm.activeItems.findIndex((el) => el == item.id) != -1,
                    },
                    attrs: { dark: true, id: item.id },
                  },
                  [
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.handleItemClick(item)
                          },
                        },
                      },
                      [
                        _vm.struct
                          ? _c(
                              "div",
                              {
                                staticClass: "col-12",
                                staticStyle: { "padding-top": "10px" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row no-gutters align-self-center",
                                  },
                                  _vm._l(_vm.struct, function (prop, key) {
                                    return _c(
                                      "div",
                                      {
                                        key: key,
                                        staticClass: "data-col",
                                        class: {
                                          "col-2": key != "title",
                                          "col-5": key == "title",
                                        },
                                        staticStyle: {
                                          "white-space": "nowrap",
                                        },
                                      },
                                      [
                                        typeof prop === "string"
                                          ? [_vm._v(_vm._s(item[key]))]
                                          : [_vm._v(_vm._s(prop.value(item)))],
                                      ],
                                      2
                                    )
                                  }),
                                  0
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }